<template>
  <div>
    <detailsHeader :key="headerKey" />
    <div class="publish">
      <div class="tag">
        <a-icon
          type="exclamation-circle"
          theme="filled"
        />注意：系统默认不发布到学生，若要设置发布到学生的数据范围，请设置报告参数。
      </div>
      <div class="ratios">
        <div class="row">
          <a-space>
            是否发布给学生：
            <span>{{ publishStudent == 0 ? "不发布" : "发布" }}</span>
            <a-button type="link" @click="setParams">设置报告参数</a-button>
          </a-space>
        </div>
        <div class="row">
          是否发布给本单位：
          <a-radio-group v-model="publishUnion" :options="unitOptions" />
        </div>
        <div class="row">
          是否发布给学校：
          <a-radio-group v-model="publishSchool" :options="schoolOptions" />
        </div>
        <div class="row">
          是否允许学校教师查看学生答题卡：
          <a-radio-group
            v-model="publishSchoolIsAllowTeacherViewAnswerSheet"
            :options="sheetOptions"
          />
        </div>
      </div>
      <template v-if="publishSchool == 2"
        ><div class="title">选择发布范围</div>
        <a-space style="margin-bottom: 18px">
          <a-input
            v-model="input"
            style="width: 180px"
            allow-clear
            placeholder="请输入学校名称"
          ></a-input>
          <a-button type="primary" ghost @click="searchSubmit">搜索</a-button>
        </a-space>
        <div style="margin-bottom: 18px">
          <a-space>
            <a-button type="link" @click="allCheck">全选</a-button>
            <a-button type="link" @click="reverse">反选</a-button>
          </a-space>
        </div>
        <div>
          <a-checkbox-group
            v-model="publishSchoolIds"
            style="margin-bottom: 18px"
          >
            <a-checkbox
              v-for="(item, index) in schoolList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-checkbox>
          </a-checkbox-group>
        </div></template
      >
      <div class="button">
        <a-button type="primary" :loading="btnLoading" @click="submit"
          >确认发布</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import { getstatschooloption, getstatinfo } from "@/core/api/academic/common";
import { getObj } from "@/core/api/exam/exam";
import { unionPublish } from "@/core/api/academic/unionReport";
export default {
  name: "",
  components: {
    detailsHeader,
  },
  data() {
    return {
      headerKey: Math.random(),
      publishSchoolIds: [],
      examInformation: {},
      schoolList: [],
      originSchool: [],
      publishUnion: 0,
      unitOptions: [
        { label: "不发布", value: 0 },
        { label: "发布", value: 1 },
      ],
      publishSchool: 0,
      schoolOptions: [
        { label: "不发布", value: 0 },
        { label: "发布所有学校", value: 1 },
        { label: "发布部分学校", value: 2 },
      ],
      publishSchoolIsAllowTeacherViewAnswerSheet: 0,
      input: undefined,
      sheetOptions: [
        { label: "不允许", value: 0 },
        { label: "允许", value: 1 },
      ],
      btnLoading: false,
      statData: {},
      publishStudent: 0,
    };
  },
  created() {
    this.getExamObj();
    this.getSchoolList();
  },
  methods: {
    async getData() {
      const res = await getstatinfo({ id: this.$route.query.id });
      this.statData = { ...res.data.data.stat };
      this.publishUnion = this.statData.publishUnion;
      this.publishSchool = this.statData.publishSchool;
      this.publishSchoolIsAllowTeacherViewAnswerSheet =
        this.statData.publishSchoolIsAllowTeacherViewAnswerSheet;
      this.publishStudent = this.statData.publishStudent;
      if (this.statData.publishSchoolIds) {
        this.publishSchoolIds = this.statData.publishSchoolIds
          .split(",")
          .map(Number);
      }
    },
    setParams() {
      const { href } = this.$router.resolve({
        path: "/academic/union/params",
        query: {
          ...this.$route.query,
        },
      });
      window.open(href, "_blank");
    },
    searchSubmit() {
      if (this.input) {
        this.schoolList = this.originSchool.filter(
          (item) => item.name.indexOf(this.input) !== -1
        );
      } else {
        this.schoolList = [...this.originSchool];
      }
    },
    allCheck() {
      this.publishSchoolIds = this.schoolList.map((item) => item.id);
    },
    reverse() {
      this.publishSchoolIds = this.schoolList.filter(
        (item) => !this.publishSchoolIds.includes(item.id)
      );
    },
    async submit() {
      this.btnLoading = true;
      try {
        await unionPublish({
          publishUnion: this.publishUnion,
          publishSchool: this.publishSchool,
          publishSchoolIsAllowTeacherViewAnswerSheet:
            this.publishSchoolIsAllowTeacherViewAnswerSheet,
          statId: this.$route.query.id,
          publishSchoolIds: this.publishSchoolIds.join(","),
        });
        this.$message({
          type: "success",
          showClose: true,
          message: "发布成功",
        });
        this.getData();
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = res.data.data;
      this.originSchool = res.data.data;
      this.getData();
    },
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.publish {
  box-sizing: border-box;
  padding: 18px;
  background: #fff;

  width: 100%;
  margin-top: 18px;
  .tag {
    height: 40px;
    background: #fff3f3;
    border-radius: 4px;
    line-height: 40px;
    color: #292626;
    font-size: 14px;
    margin-bottom: 18px;
    i {
      padding: 0 10px;
      color: #f56c6b;
    }
  }
  .ratios {
    margin-bottom: 18px;
    background: #f9fafc;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 18px;
    .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 18px;
    }
  }
  .title {
    color: #0a1119;
    font-size: 14px;
    border-radius: 4px;
    height: 36px;
    background: #f9fafc;
    box-sizing: border-box;
    padding: 0 8px;
    line-height: 36px;
    margin-bottom: 18px;
  }
  .button {
    text-align: center;
  }
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
::v-deep .ant-checkbox-wrapper {
  margin-right: 8px;
  margin-bottom: 18px;
}
</style>
